/* eslint-disable @typescript-eslint/ban-ts-comment */
import { styled } from "styled-components";
import { WarningAlt } from "@carbon/icons-react";

// @ts-ignore
import { API, EventEmitter } from "@roambee/client-utility";
// @ts-ignore
import { InputPassword, Button, Snackbar, Loader, Card, Body } from "@roambee/client-styleguide";

import Logo from "../assets/logo.png";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";

export const FullWidthButton = styled(Button)`
	width: 100%;
	margin-top: 16px;
	> span {
		width: 100%;
		text-align: center;
	}
`;
const GradientWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	background: radial-gradient(circle at 40% 30%, rgba(255, 227, 132, 0.5), transparent 30%), radial-gradient(circle at 60% 35%, rgba(157, 149, 254, 0.5), transparent 40%), radial-gradient(circle at 50% 80%, rgba(191, 248, 247, 0.5), transparent 30%);
`;
const SetPasswordWrapper = styled.div`
	width: 500px;
	padding: 38px;
`;
const ContentWrapper = styled.div`
	padding: 24px;
	margin: 30px 0;
`;
const SetPasswordCard = styled(Card)`
	width: 500px;
`;
const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
const MessageWrapper = styled.div`
	margin-top: 16px;
`;

function SetPassword() {
	const { token } = useParams();
	const [isTokenValid, setIsTokenValid] = useState(null);
	const [isPasswordChanged, setIsPasswordChanged] = useState(null);
	const navigate = useNavigate();

	const [user, setUser] = useState({
		password: "",
		confirmPassword: ""
	});

	useEffect(() => {
		API("GET", "/auth/resetpassword/" + token, {})
			.then((result) => {
				// Check the response and update state accordingly
				setIsTokenValid(true);
				if (containsHtmlTags(result)) {
					setIsTokenValid(false);
				}
			})
			.catch((error) => {
				if (error.status === 404 && error.message) {
					// console.log(error.message);
					setIsTokenValid(false);
				} else {
					console.error("Other Error:", error);
					EventEmitter.emit("showSnackbar", {
						icon: <WarningAlt size={20} />,
						id: "error",
						leftIcon: true,
						message: error?.message || "Something went wrong!",
						variant: "error",
						ariaLabel: "close button",
						position: "top-right"
					});
				}
			});
	}, [token]);

	const handleUser = (e) => {
		const { name, value } = e.target;

		setUser({
			...user,
			[name]: value
		});
	};

	// Function to check if a string contains HTML tags
	const containsHtmlTags = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

	const savePassword = () => {
		if (user.password === "" || user.confirmPassword === "") {
			// Show snackbar message when passwords don't match
			EventEmitter.emit("showSnackbar", {
				icon: <WarningAlt size={20} />,
				id: "error",
				leftIcon: true,
				message: "Password cannot be empty. Please enter a password.",
				variant: "error",
				ariaLabel: "close button",
				position: "top-right"
			});
		} else if (user.password !== user.confirmPassword) {
			// Show snackbar message when passwords don't match
			EventEmitter.emit("showSnackbar", {
				icon: <WarningAlt size={20} />,
				id: "error",
				leftIcon: true,
				message: "Passwords Do Not Match. Please ensure that you enter the same password in both fields.",
				variant: "error",
				ariaLabel: "close button",
				position: "top-right"
			});
		} else {
			// Passwords match, proceed with saving logic
			const params = {
				password: user.password
			};
			// ... perform the necessary action
			API("POST", "/auth/resetpassword/" + token, params)
				.then((result) => {
					setIsPasswordChanged(true);
					setTimeout(() => {
						navigate("/auth");
					}, 3000);
				})
				.catch((error) => {
					if (error.status === 404 && error.message) {
						// console.log(error.message);
						setIsPasswordChanged(false);
					} else {
						console.error("Other Error:", error);
						EventEmitter.emit("showSnackbar", {
							icon: <WarningAlt size={20} />,
							id: "error",
							leftIcon: true,
							message: error?.response?.data?.message || error?.message || "Something went wrong!",
							variant: "error",
							ariaLabel: "close button",
							position: "top-right"
						});
					}
				});
		}
	};

	return (
		<section id="set-password">
			<Snackbar />
			<GradientWrapper />
			<SetPasswordWrapper>
				<SetPasswordCard>
					<img src={Logo} className="rb-logo" alt="logo" />
					<ContentWrapper>
						{isTokenValid === null && <Loader />}
						{isTokenValid === false && <p className="pt-2">The password reset token is invalid or has expired.</p>}
						{isTokenValid === true && (
							<InputWrapper>
								<InputPassword id="1" label="Enter new password" name="password" placeholder="Enter password" defaultValue={user.password} onChange={handleUser} />
								<InputPassword id="2" label="Confirm new password" name="confirmPassword" placeholder="Enter password" defaultValue={user.confirmPassword} onChange={handleUser} />
								<FullWidthButton label="Save" variant="primary" size="medium" onClick={savePassword} />
							</InputWrapper>
						)}
						{isPasswordChanged && (
							<MessageWrapper>
								<Body>The account password has been reset. Please use the new password to log in.</Body>
							</MessageWrapper>
						)}
					</ContentWrapper>
				</SetPasswordCard>
			</SetPasswordWrapper>
		</section>
	);
}

export default SetPassword;
