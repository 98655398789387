/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Fragment, useState } from "react";
import { WarningAlt, CheckmarkOutline, UserAvatar } from "@carbon/icons-react";

import Logo from "../assets/logo.png";

// @ts-ignore
import { API, EventEmitter } from "@roambee/client-utility";
// @ts-ignore
import { InputText, InputPassword, Button, Snackbar, Card, Title, AppThemes, Small } from "@roambee/client-styleguide";

const GradientWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	background: radial-gradient(circle at 40% 30%, rgba(255, 227, 132, 0.5), transparent 30%), radial-gradient(circle at 60% 35%, rgba(157, 149, 254, 0.5), transparent 40%), radial-gradient(circle at 50% 80%, rgba(191, 248, 247, 0.5), transparent 30%);
`;
const LoginWrapper = styled.div`
	width: 500px;
	padding: 38px;
`;
const ContentWrapper = styled.div`
	padding: 24px;
	margin-bottom: 30px;
`;
const ContentFooter = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	margin-top: 60px;
	> Button {
		flex: 1;
		width: 50%;
		> span {
			width: 100%;
			text-align: center;
		}
	}
`;
const LoginCard = styled(Card)`
	width: 500px;
`;
const CenterTitle = styled(Title)`
	text-align: center;
	padding: 36px 0;
	font-weight: ${AppThemes.light.weights.bold700} !important;
`;
const Avatar = styled.div`
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 2rem;
	height: 2rem;
	background-color: ${AppThemes.light.colors.yellowBee200};
	border-radius: 50%;
`;

export const Initial = styled(Small)`
	text-transform: capitalize;
`;
export const ProfileContainer = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	padding: 16px;
	cursor: pointer;
	&:hover {
		background: ${AppThemes.light.colors.neutral100} 0% 0% no-repeat padding-box;
		border-radius: 8px;
	}
`;
const ProfileTitle = styled(Title)`
	font-size: 16px !important;
	font-weight: ${AppThemes.light.weights.bold700} !important;
`;
const Separator = styled.div`
	height: 0;
	border: 0.9px solid ${AppThemes.light.colors.neutral300};
	margin: 8px 0;
`;
const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
const ForgotButton = styled(Button).attrs({
	variant: "no-bg",
	size: "small"
})`
	float: right;
	padding: 0 !important;
`;

// Helper functions
const getAuthUserFromCookie = () => {
	const match = document.cookie.match(/(?:^|;) *RoambeeAuthUser=([^;]*)/);
	if (!match) {
		return null;
	}

	try {
		const authUser = JSON.parse(decodeURIComponent(match[1]));
		return authUser;
	} catch (error) {
		console.error("Error parsing auth user from cookie:", error);
		return null;
	}
};

const getKCIdpHint = (uri, queryParams, defaultIdp) => {
	const match = uri.match(/([^?]+)\?(.*)/);
	const baseUrl = match[1];
	const params = match[2].split("&");

	let kc_idp_hintValue = params.find((paramValuePair) => paramValuePair.startsWith("kc_idp_hint"))?.split("=")[1];
	kc_idp_hintValue = kc_idp_hintValue || defaultIdp;

	return `${baseUrl}?${queryParams}&kc_idp_hint=${kc_idp_hintValue}`;
};

function Login({ source, queryParams, fromUrl }) {
	const navigate = useNavigate();

	// Retrieve the value of the RoambeeAuthUser cookie
	const authUser = getAuthUserFromCookie();

	const [user, setUser] = useState({
		username: "",
		password: ""
	});
	const [showPassword, setShowPassword] = useState(false);
	const [isNewUser, setIsNewUser] = useState(false);

	const handleUser = (e) => {
		const { name, value } = e.target;

		setUser({
			...user,
			[name]: value
		});
	};

	const handleNewUser = () => {
		setShowPassword(false);
		setUser({
			...user,
			password: ""
		});
		setIsNewUser(!isNewUser);
	};

	const handleFormSubmit = () => {
		if (showPassword) {
			const args = {
				email: user.username,
				password: user.password
			};

			API("POST", "/auth/login", args)
				.then(() => {
					navigate("/");
				})
				.catch((error) => {
					if (error.status === 404 && error.message) {
						// console.log(error.message);
					} else {
						console.error("Other Error:", error);
						EventEmitter.emit("showSnackbar", {
							icon: <WarningAlt size={20} />,
							id: "error",
							leftIcon: true,
							message: error?.response?.data || error?.message || "Something went wrong!",
							variant: "error",
							ariaLabel: "close button",
							position: "top-right"
						});
					}
				});
		} else {
			const params = {
				...user,
				source: source?.toLowerCase().includes("hcng") ? "hive" : source || "hive", // we have to re-factor this once we make change in webserver
				...(fromUrl && { fromUrl })
			};

			API("POST", "/auth/check-username", params)
				.then((result) => {
					if (!result.sso) {
						setShowPassword(true);
					} else {
						const redirectUrl = source?.toLowerCase().includes("hcng") ? getKCIdpHint(result.uri, queryParams, process.env.ROAMBEE_IDP) : result.uri;
						window.location.assign(redirectUrl);
					}
				})
				.catch((error) => {
					if (error.status === 404 && error.message) {
						// console.log(error.message);
					} else {
						console.error("Other Error:", error);
						EventEmitter.emit("showSnackbar", {
							icon: <WarningAlt size={20} />,
							id: "error",
							leftIcon: true,
							message: error?.message || "Something went wrong!",
							variant: "error",
							ariaLabel: "close button",
							position: "top-right"
						});
					}
				});
		}
	};

	const handleForgot = () => {
		const params = {
			email: user.username
		};

		API("POST", "/auth/forgot", params)
			.then((result) => {
				if (result && result.message === "Success") {
					EventEmitter.emit("showSnackbar", {
						icon: <CheckmarkOutline size={20} />,
						id: "error",
						leftIcon: true,
						message: "Success! Password Reset Email Sent.",
						variant: "successful",
						ariaLabel: "close button",
						position: "top-right"
					});
				}
			})
			.catch((error) => {
				if (error.status === 404 && error.message) {
					// console.log(error.message);
				} else {
					console.error("Other Error:", error);
					EventEmitter.emit("showSnackbar", {
						icon: <WarningAlt size={20} />,
						id: "error",
						leftIcon: true,
						message: error?.message || "Something went wrong!",
						variant: "error",
						ariaLabel: "close button",
						position: "top-right"
					});
				}
			});
	};

	const handleAutoLogin = () => {
		const params = {
			username: authUser.email,
			source: source?.toLowerCase().includes("hcng") ? "hive" : source || "hive", // we have to re-factor this once we make change in webserver
			...(fromUrl && { fromUrl })
		};

		API("POST", "/auth/check-username", params)
			.then((result: any) => {
				if (!result.sso) {
					EventEmitter.emit("showSnackbar", {
						icon: <WarningAlt size={20} />,
						id: "message",
						leftIcon: true,
						message: "You are not currently an SSO user. Please enable SSO or Log in as a new user.",
						variant: "neutral",
						ariaLabel: "close button",
						position: "top-right"
					});
				} else {
					const redirectUrl = source?.toLowerCase().includes("hcng") ? getKCIdpHint(result.uri, queryParams, process.env.ROAMBEE_IDP) : result.uri;
					window.location.assign(redirectUrl);
				}
			})
			.catch((error) => {
				console.error("Other Error:", error);
				EventEmitter.emit("showSnackbar", {
					icon: <WarningAlt size={20} />,
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
			});
	};

	const AuthUserLogin = () => {
		return (
			<Fragment>
				<CenterTitle>Choose User</CenterTitle>
				<ProfileContainer onClick={handleAutoLogin}>
					<Avatar>
						<Initial $bold>{authUser.name[0]}</Initial>
					</Avatar>
					<div>
						<ProfileTitle>{authUser.name}</ProfileTitle>
						<Small>{authUser.email}</Small>
					</div>
				</ProfileContainer>
				<Separator></Separator>
				<ProfileContainer onClick={handleNewUser}>
					<UserAvatar size={30} />
					<ProfileTitle>Log in as new user</ProfileTitle>
				</ProfileContainer>
			</Fragment>
		);
	};

	return (
		<section id="login">
			<Snackbar />
			<GradientWrapper />
			<LoginWrapper>
				{authUser && !isNewUser ? (
					<LoginCard>
						<img src={Logo} className="rb-logo" alt="logo" />
						<ContentWrapper>
							<AuthUserLogin />
						</ContentWrapper>
					</LoginCard>
				) : (
					<LoginCard>
						<img src={Logo} className="rb-logo" alt="logo" />
						<ContentWrapper>
							<CenterTitle>Let’s get started!</CenterTitle>
							<InputWrapper>
								<InputText id="1" label="Username" name="username" placeholder="Enter username" defaultValue={user.username} onChange={handleUser} />
								{showPassword && (
									<Fragment>
										<InputPassword id="1" label="Password" name="password" placeholder="Enter password" defaultValue={user.password} onChange={handleUser} />
									</Fragment>
								)}
							</InputWrapper>
							<Fragment>
								<ForgotButton label="Forgot password?" onClick={handleForgot} />
							</Fragment>
							<ContentFooter>
								{authUser && <Button label="Back" onClick={handleNewUser} variant="secondary" size="medium" />}
								<Button label={showPassword ? "Sign in" : "Continue"} onClick={handleFormSubmit} variant="primary" size="medium" />
							</ContentFooter>
						</ContentWrapper>
					</LoginCard>
				)}
			</LoginWrapper>
		</section>
	);
}

export default Login;
