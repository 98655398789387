/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";

// css
import "./App.scss";

// local files
import Login from "./components/Login";
import SetPassword from "./components/SetPassword";

function App() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	let source = urlParams.get("source"); // view | resolve | reveal | cosmos
	const fromUrl = urlParams.get("fromUrl");
	const queryParams = urlParams.toString();

	if (!source) {
		source = urlParams.get("client_id");
	}

	return (
		<section id="container">
			<Routes>
				<Route path="/auth">
					<Route index element={<Login source={source} queryParams={queryParams} fromUrl={fromUrl} />} />
					<Route path="set-password/:token" element={<SetPassword />} />
					{/* Add more nested routes if needed */}
				</Route>
			</Routes>
		</section>
	);
}

export default App;
